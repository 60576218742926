<template>
  <div id="app">
    <h1>查询 UserToken 信息</h1>
    <input v-model="userToken" placeholder="请输入 UserToken" />
    <button @click="checkUserToken">查询</button>
    <p v-if="result">{{ result }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userToken: '',
      result: ''
    };
  },
  methods: {
    async checkUserToken() {
      const url = '/api/adminapi/chatgpt/user/page';  // 使用你提供的接口地址
      const headers = {
        'Content-Type': 'application/json',
        'apiauth': 'obtqzbqwgbuCys5eopkc'  // 替换为你的 API AUTH
      };
      const payload = {
        page: 1,
        size: 20,
        keyWord: this.userToken
      };

      try {
        const response = await axios.post(url, payload, { headers });
        const responseData = response.data;

        if (responseData.code === 1000 && responseData.data) {
          const dataList = responseData.data.list;
          if (dataList && dataList.length > 0) {
            const entry = dataList[0];
            this.result = `UserToken 存在. 到期时间: ${entry.expireTime}, 是否是 Plus 用户: ${entry.isPlus === 1 ? 'Yes' : 'No'}`;
          } else {
            this.result = 'UserToken 不存在';
          }
        } else {
          this.result = `错误：${responseData.message || '未知错误'}`;
        }
      } catch (error) {
        this.result = `请求失败：${error.message}`;
      }
    }
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 60px;
}

input {
  margin-right: 10px;
  padding: 5px;
  font-size: 16px;
}

button {
  padding: 5px 10px;
  font-size: 16px;
}

p {
  margin-top: 20px;
  font-size: 18px;
  color: green;
}
</style>
